import {
  AUTH_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_CHECK_STORAGE,
  AUTH_SUCCESS_STORAGE,
  AUTH_ERROR_STORAGE,
  AUTH_CLEAN_DATA,
} from './AuthTypes'

const initialState = {
  checkStorage: false,
  authorized: false,
  token: '',
  error: '',
  isSubmitting: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        error: null,
        isSubmitting: true
      }
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        isSubmitting: false
      }
    case AUTH_SUCCESS:
      return {
        checkStorage: true,
        authorized: true,
        error: '',
        token: action.payload,
        isSubmitting: false
      }
    case AUTH_CHECK_STORAGE:
      return {
        ...state,
        checkStorage: false,
      }
    case AUTH_SUCCESS_STORAGE:
      return {
        ...state,
        checkStorage: true,
        authorized: true,
        token: action.payload,
      }
    case AUTH_ERROR_STORAGE:
      return {
        ...state,
        checkStorage: true,
        authorized: false,
        token: '',
      }
    case AUTH_CLEAN_DATA:
      return {
        checkStorage: true,
        authorized: false,
        token: '',
        error: ''
      }
    default:
      return state
  }
};

export default authReducer;
