import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box, Button,
  Dialog, DialogActions, DialogContent, DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import CircularProgressWithLabel from "../../components/ui/CircularProgressWithLabel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Colors } from "../../consts/colors";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as ProfileActions from "../../modules/Profile/ProfileAction";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../services/LocalStorageService";
import Loader from "../../components/ui/Loader";

const Profile = () => {
  const {t} = useTranslation();
  const [showRemoveToast, setShowRemoveToast] = useState("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localStorageFunctions = useLocalStorage();
  const firstUpdate = useRef(true);
  const progress = useSelector((state: any) => state.profile.progress);
  const groups = useSelector((state: any) => state.profile.groups);
  const removeError = useSelector((state: any) => state.profile.removeError);
  const removeSuccess = useSelector((state: any) => state.profile.removeSuccess);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      dispatch(ProfileActions.requestProfileProgress());
      dispatch(ProfileActions.requestProfileGroups());

      return;
    }
  }, [dispatch]);

  useEffect(() => {
    if (removeError !== '') {
      setShowRemoveToast(removeError);
    }

    if (removeSuccess > 0) {
      setShowRemoveToast(t('removeProfile:success'));
    }
  }, [removeError, removeSuccess, t]);

  const handleDeleteAccount = () => {
    dispatch(ProfileActions.requestRemoveProfile(localStorageFunctions));
  };

  const handleClickOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const showToast = (msg: string): void => {
    toast.success(msg, {position: "bottom-center"});

    setTimeout(() => {
      dispatch(ProfileActions.requestRemoveProfileClear());
      setShowRemoveToast("");
    }, 500);
  };

  return (
    <>
      {showRemoveToast !== "" && showToast(showRemoveToast)}
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/dashboard")}}><ArrowBackIosNewIcon/></IconButton>,
        text: "Twój profil"
      }}>
        {groups.length === 0 ? (
          <Loader/>
        ) : (
          <>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} paddingY={4}>
              <CircularProgressWithLabel fontSize={24} props={{thickness: 1, size: 120, value: progress}} />
            </Box>

            <List sx={{overflow: "auto"}}>
              {groups.map((res: any) => (
                <ListItem key={res.id} disablePadding>
                  <ListItemButton divider onClick={() => navigate("/groupFields", {state: {groupId: res.id, label: res.name}})}>
                    <ListItemText primaryTypographyProps={{variant: "body2"}} primary={res.name} />
                    <CircularProgressWithLabel fontSize={12} props={{thickness: 1, size: 36, value: res.percentage}} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Typography
              onClick={handleClickOpenDialog}
              paddingY={3}
              color={Colors.error}
              variant={"body2"}
              align={"center"}
            >
              Chcę usunąć konto
            </Typography>

            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('removeProfile:title')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button fullWidth onClick={() => {handleCloseDialog(); handleDeleteAccount()}}>
                  {t('text:yes')}
                </Button>
                <Button fullWidth onClick={handleCloseDialog} autoFocus>{t('text:no')}</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </ToolbarWrapper>
    </>
  );
};

export default Profile;