import {
  PROFILE_GROUPS_ERROR,
  PROFILE_GROUPS_REQUEST,
  PROFILE_GROUPS_SUCCESS,
  PROFILE_PROGRESS_ERROR,
  PROFILE_PROGRESS_REQUEST,
  PROFILE_PROGRESS_SUCCESS,
  PROFILE_REMOVE_REQUEST,
  PROFILE_REMOVE_ERROR,
  PROFILE_REMOVE_SUCCESS, PROFILE_REMOVE_CLEAR,
} from './ProfileType';

const initialState = {
  progressIsFetching: false,
  progressError: '',
  progress: 0,

  groupsIsFetching: false,
  groupsError: '',
  groups: [],

  removeRequest: 0,
  removeError: "",
  removeSuccess: 0,
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PROGRESS_SUCCESS:
      const roundedProgress = Math.round(action.payload.progress * 10) / 10
      return {
        ...state,
        progressError: '',
        progressIsFetching: false,
        progress: roundedProgress,
      }
    case PROFILE_PROGRESS_ERROR:
      return {
        ...state,
        progressError: action.payload,
        progressIsFetching: false
      }
    case PROFILE_PROGRESS_REQUEST:
      return {
        ...state,
        progressError: '',
        progressIsFetching: true
      }
    case PROFILE_GROUPS_SUCCESS:
      return {
        ...state,
        groupsError: '',
        groupsIsFetching: false,
        groups: action.payload.profile_groups,
      }
    case PROFILE_GROUPS_REQUEST:
      return {
        ...state,
        groupsError: '',
        groupsIsFetching: true,
      }
    case PROFILE_GROUPS_ERROR:
      return {
        ...state,
        groupsIsFetching: false,
        groupsError: action.payload
      }
    case PROFILE_REMOVE_SUCCESS:
      return {
        ...state,
        removeRequest: 0,
        removeError: "",
        removeSuccess: 1,
      }
    case PROFILE_REMOVE_REQUEST:
      return {
        ...state,
        removeRequest: 1,
        removeError: '',
        removeSuccess: 0,
      }
    case PROFILE_REMOVE_ERROR:
      return {
        ...state,
        removeRequest: 0,
        removeError: action.payload,
        removeSuccess: 0,
      }
    case PROFILE_REMOVE_CLEAR:
      return {
        ...state,
        removeRequest: 0,
        removeError: "",
        removeSuccess: 0,
      }
    default:
      return state
  }
};

export default profileReducer;