import { EXCHANGE_LIST_REQUEST, EXCHANGE_LIST_ERROR, EXCHANGE_LIST_SUCCESS } from './ExchangeListTypes'
import API from '../../utils/api'

export const exchangeListSuccess = (payload) => ({
  type: EXCHANGE_LIST_SUCCESS,
  payload,
})

export const exchangeListRequest = () => ({
  type: EXCHANGE_LIST_REQUEST,
})

export const exchangeListError = (error) => ({
  type: EXCHANGE_LIST_ERROR,
  payload: error,
})

export const exchangeListAction = () => {
  return async (dispatch) => {
    dispatch(exchangeListRequest())
    await API.Instance.get('/exchanges_possible_items').then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(exchangeListError(response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później."));
        return;
      }

      const {exchanges_products} = response.data.data;

      dispatch(exchangeListSuccess(exchanges_products));
    })
  }
}
