import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import { object, string } from "yup";
import I18n from "i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as ExchangeActions from '../../modules/Exchange/ExchangeAction';
import * as UserActions from '../../modules/User/UserAction';
import { useNavigate } from "react-router-dom";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 2,
  overflow: "auto",
  maxHeight: "100%"
};

const validationSchema = object().shape({
  account_number: string()
    .matches(/^[0-9 ]+$/, I18n.t('exchange:accountNumberType'))
    .required(I18n.t('exchange:accountNumberRequired')),
  firstname: string().required(I18n.t('exchange:nameRequired')),
  lastname: string().required(I18n.t('exchange:lastnameRequired')),
  city: string().required(I18n.t('exchange:cityRequired')),
  zip: string().required(I18n.t('exchange:zipRequired')),
  pesel: string().matches(/^[0-9 ]+$/, I18n.t('exchange:peselNumberType')),
  nip: string().matches(/^[0-9 ]+$/, I18n.t('exchange:nipNumberType')),
});

const ExhchangeModal = ({isOpen, onClose, exchangeFormikValues}) => {
  const {t} = useTranslation("exchange");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accountNumberError, setAccountNumberError] = useState('');
  const formik: any = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      ...exchangeFormikValues
    },
    onSubmit: (values: any): void => {
      if (accountNumberError !== '') {
        toast.error(t('exchange:accountNumberLength'), {position: "bottom-center"});
        return;
      }

      dispatch(ExchangeActions.exchangeAction(values));
      dispatch(UserActions.userAction());
      onClose();
      navigate("/");
      toast.success(t('exchange:success'), {position: "bottom-center"});
    }
  });

  useEffect(() => {
    formik.setValues(exchangeFormikValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeFormikValues]);

  const accountNumberValidation = (value: string): void => {
    let number = value.replace(new RegExp(' ', 'g'), '');

    if (number.length !== 26) {
      setAccountNumberError(t('exchange:accountNumberLength'));
    } else {
      setAccountNumberError('');
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("modalTitle")}
              </Typography>

              <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>

            <Box paddingY={1}>
              <Typography variant={"body2"}>{t('modalReg')}</Typography>
              <Typography variant={"body2"} color={"red"}>
                Po zleceniu wymiany punktów otrzyma Pan/i e-mail z linkiem do potwierdzenia chęci
                dokonania wymiany punktów. Jeżeli wymiana nie zostanie potwierdzona (poprzez kliknięcie
                w link znajdujący się w wiadomości) wówczas nie zostanie ona przekazana do realizacji.
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Box marginBottom={1}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    autoComplete="accountNumber"
                    variant="standard"
                    name="accountNumber"
                    type="text"
                    label={t("accountNumber")}
                    helperText={!!(formik.touched.account_number && formik.errors.account_number) && formik.errors.account_number}
                    onChange={formik.handleChange("account_number")}
                    value={formik.values.account_number}
                    error={!!(formik.touched.account_number && formik.errors.account_number)}
                    onBlur={() => accountNumberValidation(formik.values.account_number)}
                  />
                </FormControl>
              </Box>

              <Box marginBottom={1}>
                <FormControl fullWidth variant="standard" sx={{display: "flex", flexDirection: "row", gap: 1}}>
                  <TextField
                    autoComplete="firstname"
                    variant="standard"
                    name="firstname"
                    type="text"
                    label={t("firstname")}
                    helperText={!!(formik.touched.firstname && formik.errors.firstname) && formik.errors.firstname}
                    onChange={formik.handleChange("firstname")}
                    value={formik.values.firstname}
                    error={!!(formik.touched.firstname && formik.errors.firstname)}
                  />
                  <TextField
                    autoComplete="lastname"
                    variant="standard"
                    name="lastname"
                    type="text"
                    label={t("lastname")}
                    helperText={!!(formik.touched.lastname && formik.errors.lastname) && formik.errors.lastname}
                    onChange={formik.handleChange("lastname")}
                    value={formik.values.lastname}
                    error={!!(formik.touched.lastname && formik.errors.lastname)}
                  />
                </FormControl>
              </Box>

              <Box marginBottom={1}>
                <FormControl fullWidth variant="standard" sx={{display: "flex", flexDirection: "row", gap: 1}}>
                  <TextField
                    autoComplete="street"
                    variant="standard"
                    sx={{width: "60%"}}
                    name="login"
                    type="text"
                    label={t("street")}
                    helperText={!!(formik.touched.street && formik.errors.street) && formik.errors.street}
                    onChange={formik.handleChange("street")}
                    value={formik.values.street}
                    error={!!(formik.touched.street && formik.errors.street)}
                  />
                  <TextField
                    autoComplete="username"
                    variant="standard"
                    name="login"
                    sx={{width: "40%"}}
                    type="text"
                    label={t("home")}
                    helperText={!!(formik.touched.home && formik.errors.home) && formik.errors.home}
                    onChange={formik.handleChange("home")}
                    value={formik.values.home}
                    error={!!(formik.touched.home && formik.errors.home)}
                  />
                </FormControl>
              </Box>

              <Box marginBottom={1}>
                <FormControl fullWidth variant="standard" sx={{display: "flex", flexDirection: "row", gap: 1}}>
                  <TextField
                    autoComplete="city"
                    variant="standard"
                    sx={{width: "60%"}}
                    name="city"
                    type="text"
                    label={t("city")}
                    helperText={!!(formik.touched.city && formik.errors.city) && formik.errors.city}
                    onChange={formik.handleChange("city")}
                    value={formik.values.city}
                    error={!!(formik.touched.city && formik.errors.city)}
                  />
                  <TextField
                    autoComplete="username"
                    variant="standard"
                    name="zip"
                    sx={{width: "40%"}}
                    type="text"
                    label={t("zip")}
                    helperText={!!(formik.touched.zip && formik.errors.zip) && formik.errors.zip}
                    onChange={formik.handleChange("zip")}
                    value={formik.values.zip}
                    error={!!(formik.touched.zip && formik.errors.zip)}
                  />
                </FormControl>
              </Box>

              <Box marginBottom={1}>
                <FormControl fullWidth variant="standard" sx={{display: "flex", flexDirection: "row", gap: 1}}>
                  <TextField
                    autoComplete="username"
                    variant="standard"
                    name="pesel"
                    type="text"
                    label={t("pesel")}
                    helperText={!!(formik.touched.pesel && formik.errors.pesel) && formik.errors.pesel}
                    onChange={formik.handleChange("pesel")}
                    value={formik.values.pesel}
                    error={!!(formik.touched.pesel && formik.errors.pesel)}
                  />
                  <TextField
                    autoComplete="username"
                    variant="standard"
                    name="nip"
                    type="text"
                    label={t("nip")}
                    helperText={!!(formik.touched.nip && formik.errors.nip) && formik.errors.nip}
                    onChange={formik.handleChange("nip")}
                    value={formik.values.nip}
                    error={!!(formik.touched.nip && formik.errors.nip)}
                  />
                </FormControl>
              </Box>

              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Button type={"submit"} fullWidth size={"large"} variant={"contained"}>
                  {t('exchangePoints')}
                </Button>
                <Button fullWidth size={"large"} variant="text" onClick={onClose}>
                  {t('cancel')}
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ExhchangeModal;