import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import * as GroupFieldsActions from "../../modules/GroupFields/GroupFieldsAction";
import { useDispatch, useSelector } from "react-redux";
import ProfileField from "../ProfileField";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Colors } from "../../consts/colors";
import isEqual from 'lodash/isEqual';

const GroupFields = () => {
  const {t} = useTranslation('login');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const firstUpdate = useRef(true);
  const [initialForm, setInitialForm] = useState<{}>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const isFetching = useSelector((state: any) => state.groupFields.groupFieldsIsFetching);
  const groupFieldsArray = useSelector((state: any) => state.groupFields.groupFieldsArray);
  const groupFields = useSelector((state: any) => state.groupFields);
  const groupId = location.state && location.state.groupId;
  const label = location.state && location.state.label;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialForm,
    onSubmit: (values) => {
      dispatch(GroupFieldsActions.submitGroupFields(values))
        .then((res) => {
          toast.success(res, {position: "bottom-center"});
          navigate("/profile");
        })
        .catch((err) => {
          toast.error(err, {position: "bottom-center"});
        })
    }
  });
  const unsavedChanges = useRef(false)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      setInitialForm({});
      dispatch(GroupFieldsActions.requestGroupFields(groupId));

      return;
    }

    return () => {
      dispatch(GroupFieldsActions.clearGroupFields());
    }
  }, [dispatch, groupId]);

  useEffect(() => {
    if (!groupFields.groupFieldsIsFetching) {
      const initialValues = {};

      for (let f in groupFieldsArray) {
        const field = groupFieldsArray[f];

        if (field.type === 'checkbox') {
          const options = {};

          for (let o in field.options) {
            const option = field.options[o];
            options[option.value] = Number(option.selected);
          }

          initialValues[field.name] = options;
        }

        if (field.type === 'text') {
          initialValues[field.name] = field.value;
        }

        if (field.type === 'select') {
          field['options'] = field.options.filter((option) => !!option.label);
          const selectedValue = field.options.filter((option) => option.selected);

          if (selectedValue.length > 0) {
            initialValues[field.name] = selectedValue[0].value;
          } else {
            initialValues[field.name] = '';
          }
        }

        if (field.type === 'calendar') {
          initialValues[field.name] = field.value
        }
      }

      setInitialForm(initialValues);
    }
  }, [groupFields.groupFieldsIsFetching, groupFieldsArray, isFetching])

  useEffect(() => {
    if (!isEqual(formik.values, formik.initialValues)) {
      unsavedChanges.current = true;
    }
  }, [formik.initialValues, formik.values]);

  const handleClickOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {
          if (unsavedChanges.current) {
            handleClickOpenDialog();
            return;
          }

          navigate("/profile");
        }}><ArrowBackIosNewIcon/></IconButton>,
        text: "Twój profil",
        rightItems: <Typography color={"#009688"} onClick={formik.submitForm}>Zapisz</Typography>
      }}>
        <>
          <Typography padding={2} variant={"h6"} paddingBottom={1}>{label}</Typography>

          {isFetching ? (
            <Box paddingY={2} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress/>
            </Box>
          ) : (
            <Box padding={2} paddingTop={0} overflow={"auto"}>
              <form onSubmit={formik.handleSubmit}>
                <Box display={"flex"} flexDirection={"column"} gap={2.5}>
                  {groupFieldsArray.map((field: any, index: number) => (
                    <ProfileField
                      key={index}
                      field={field} value={formik.values[field.name]} onChange={formik.handleChange} setFieldValue={formik.setFieldValue}
                    />
                  ))}
                </Box>
                <Button type={"submit"} fullWidth variant={"text"} sx={{marginTop: 3, marginBottom: 1}}>{t('form:submit')}</Button>
              </form>
            </Box>
          )}

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle textAlign={"center"} id="alert-dialog-title">
              {t('form:discardChangesTitle')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText textAlign={"center"} id="alert-dialog-description">
                {t('form:discardChangesText')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button fullWidth onClick={handleCloseDialog}>
                {t('form:discardChangesDontLeave')}
              </Button>
              <Button sx={{color: Colors.error}} fullWidth onClick={() => navigate("/profile")} autoFocus>{t('form:discardChangesLeave')}</Button>
            </DialogActions>
          </Dialog>
        </>
      </ToolbarWrapper>
    </>
  );
};

export default GroupFields;