import React from 'react';
import { Box } from '@mui/material';
import DefaultToolbar from "../DefaultToolbar";
import { ToolbarWrapperProps } from "./types";
import { SxProps } from "@mui/system";

const ToolbarWrapper: React.FC<ToolbarWrapperProps> = ({
  hasToolbar,
  children,
  toolbarConfig,
  styles = {}
}) => {
  const sxWrapperStyles: SxProps = {
    ...styles,
    paddingTop: hasToolbar ? '56px' : undefined,
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  };

  return (
    <>
      {hasToolbar && <DefaultToolbar hasLogo={toolbarConfig.hasLogo} text={toolbarConfig.text} leftItems={toolbarConfig.leftItems}
        rightItems={toolbarConfig.rightItems}/>}
      <Box sx={sxWrapperStyles}>
        {children}
      </Box>
    </>
  );
}

export default ToolbarWrapper;
