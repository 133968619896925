import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import * as SurveyActions from "../../modules/Survey/SurveyAction";
import { useSwipeable } from "react-swipeable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/ui/Loader";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Survey = () => {
  const {t} = useTranslation("survey");
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [showArchive, setShowArchive] = useState(false)
  const dispatch = useDispatch();
  const activeSurveys = useSelector((state: any) => state.surveys.active_surveys);
  const archiveSurveys = useSelector((state: any) => state.surveys.archive_surveys);
  const surveysFetching = useSelector((state: any) => state.surveys.isFetching);
  const listRef = useRef<any>(null);
  const firstUpdate = useRef(true);
  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      const {absY} = eventData;
      const threshold = 50;
      const isOutsideList = absY < -threshold || absY > threshold;
      const isScrolledToTop = listRef.current?.scrollTop === 0;

      if (isOutsideList && isScrolledToTop) {
        fetchOptions();
      }
    }
  });

  const fetchOptions = useCallback(() => {
    dispatch(SurveyActions.surveyAction());
  }, [dispatch]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      fetchOptions();

      return;
    }
  }, [fetchOptions]);

  useEffect(() => {
    if (!isFetching && surveysFetching) {
      setIsFetching(true)
      setIsLoading(true)
    }

    if (isFetching && !surveysFetching) {
      setIsFetching(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [isFetching, surveysFetching])

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
    setShowArchive(!(newValue === 0));
  };

  const refPassthrough = (el): void => {
    handlers.ref(el);

    listRef.current = el;
  }

  let surveys = activeSurveys;

  if (showArchive) {
    surveys = archiveSurveys;
  }

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {
          navigate("/dashboard")
        }}><ArrowBackIosNewIcon/></IconButton>,
        text: "Ankiety"
      }}>
        {isLoading ? (
          <Loader/>
        ) : (
          <>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                sx={{width: "50%", fontSize: "12px"}}
                onChange={() => {
                  setShowArchive(false)
                }}
                label={t("actual")} {...a11yProps(0)}
              />
              <Tab
                sx={{width: "50%", fontSize: "12px"}}
                onChange={() => setShowArchive(true)}
                label={t("archive")} {...a11yProps(1)}
              />
            </Tabs>

            {surveys.length === 0 ? (
              <Box padding={3}>
                <Typography textAlign={"center"} color={'#009688'}>{t('survey:noSurveys')}</Typography>
              </Box>
            ) : (
              <Box overflow={"auto"} {...handlers} ref={refPassthrough} height={"100%"}>
                <List>
                  {surveys.map((res) => (
                    <ListItem key={res.id} disablePadding>
                      <ListItemButton divider onClick={() => {
                        if (showArchive) {
                          return null
                        }

                        navigate('/surveyView', {state: {url: res.url, external: ["12", "13"].includes(res.authorization)}})
                      }}>
                        <ListItemText
                          primary={res.name}
                          primaryTypographyProps={{color: '#009688'}}
                          secondary={
                            <>
                              {showArchive ? res.user_points : res.points}{t('points')}
                              <br/>
                              {t('duration')}: {res.duration}
                            </>
                          }
                        />

                        {!showArchive && <OpenInNewIcon sx={{color: showArchive ? '#9D9D9D7A' : '#009688'}}/>}

                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </>
        )}
      </ToolbarWrapper>
    </>
  );
};

export default Survey;