import { REGISTER_REQUEST, REGISTER_ERROR, REGISTER_SUCCESS, REGISTER_CLEAR } from './RegisterType'

const initialState = {
  email: '',
  link: '',
  error: '',
  isRequesting: '',
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        email: '',
        error: null,
        isRequesting: true
      }
    case REGISTER_ERROR:
      return {
        ...state,
        error: action.payload,
        isRequesting: false
      }
    case REGISTER_SUCCESS:
      return {
        email: action.payload.email,
        link: action.payload.link,
        error: '',
        isRequesting: false
      }
    case REGISTER_CLEAR:
      return {
        link: '',
        email: '',
        error: '',
        isRequesting: false
      }
    default:
      return state
  }
};

export default registerReducer;
