import { GROUP_FIELDS_ERROR, GROUP_FIELDS_REQUEST, GROUP_FIELDS_SUCCESS, GROUP_FIELDS_CLEAR } from './GroupFieldsType'

const initialState = {
  groupFieldsError: '',
  groupFieldsIsFetching: false,
  groupFieldsArray: [],
}

const groupFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_FIELDS_SUCCESS:
      return {
        ...state,
        groupFieldsError: '',
        groupFieldsIsFetching: false,
        groupFieldsArray: action.payload.profile_fields,
      }
    case GROUP_FIELDS_ERROR:
      return {
        ...state,
        groupFieldsError: action.payload,
        groupFieldsIsFetching: false,
      }
    case GROUP_FIELDS_REQUEST:
      return {
        ...state,
        groupFieldsError: '',
        groupFieldsIsFetching: true,
      }
    case GROUP_FIELDS_CLEAR:
      return {
        ...state,
        groupFieldsArray: [],
        groupFieldsIsFetching: false,
      }
    default:
      return state
  }
};

export default groupFieldsReducer;