import React from 'react';
import { useTranslation } from "react-i18next";
import { IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../utils/linking/UniversalLinking";

const Settings = () => {
  const {t} = useTranslation('settings');
  const navigate = useNavigate();
  const email = "biuro@badanie-opinii.pl";

  const handleContact = (): void => {
    window.location.href = `mailto:${email}`;
  };

  const handlePrivacyPolice = (): void => {
    openLink('https://www.badanie-opinii.pl/polityka-prywatnosci');
  };

  const handleReg = (): void => {
    openLink('https://www.badanie-opinii.pl/regulamin');
  };

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/dashboard")}}><ArrowBackIosNewIcon/></IconButton>,
        text: "Ustawienia"
      }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton divider onClick={handleContact}>
              <ListItemText primary={t("contact")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton divider onClick={handleReg}>
              <ListItemText primary={t("reg")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton divider onClick={handlePrivacyPolice}>
              <ListItemText primary={t("privacyPolicy")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton divider onClick={() => navigate("/aboutApp")}>
              <ListItemText primary={t("about")} />
            </ListItemButton>
          </ListItem>
        </List>
      </ToolbarWrapper>
    </>
  );
};

export default Settings;