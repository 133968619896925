import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Checkbox, CircularProgress,
  FormControl,
  Link,
  TextField, Typography,
} from "@mui/material";
import { useFormik } from "formik";
import I18n from "i18next";
import { object, string } from "yup";
import { IRegisterFormProps } from "./types";
import { IRegisterValues } from "../../containers/Register/types";
import { Check } from "@mui/icons-material";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { openLink } from "../../utils/linking/UniversalLinking";

const validationSchema = object().shape({
  name: string().required(I18n.t("register:nameEmptyError")),
  lastname: string().required(I18n.t("register:passwordEmptyError")),
  email: string().required(i18next.t("errors:emailFormat")).email(i18next.t("errors:emailFormat")),
});

const RegisterForm = (props: IRegisterFormProps) => {
  const {isSubmitting, signIn, handleInputFocus, handleInputBlur} = props;
  const [isRodoConfirmed, setIsRodoConfirmed] = useState(false);
  const [isReqConfirmed, setIsReqConfirmed] = useState(false);
  const {t} = useTranslation('register');
  const navigate = useNavigate();
  const isRequesting = useSelector((state: any) => state.register.isRequesting);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      lastname: "",
      email: "",
    },
    onSubmit: (values: IRegisterValues): void => {
      signIn(values);
    }
  });

  return (
    <Box marginTop={3} width={"100%"}>
      <form onSubmit={formik.handleSubmit}>
        <Box marginBottom={1}>
          <FormControl fullWidth variant="standard">
            <TextField
              autoComplete="username"
              variant="standard"
              name="name"
              type="text"
              disabled={isSubmitting}
              label={t("name")}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              helperText={!!(formik.touched.name && formik.errors.name) && formik.errors.name}
              onChange={formik.handleChange("name")}
              value={formik.values.name}
              error={!!(formik.touched.name && formik.errors.name)}
            />
          </FormControl>
        </Box>

        <FormControl fullWidth sx={{marginY: 1}} variant="standard">
          <TextField
            label={t("lastname")}
            type="text"
            autoComplete="current-password"
            variant={"standard"}
            disabled={isSubmitting}
            name="lastname"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={formik.handleChange("lastname")}
            value={formik.values.lastname}
            helperText={!!(formik.touched.lastname && formik.errors.lastname) && formik.errors.lastname}
            error={!!(formik.touched.lastname && formik.errors.lastname)}
          />
        </FormControl>

        <FormControl fullWidth sx={{marginY: 1}} variant="standard">
          <TextField
            label={t("email")}
            variant={"standard"}
            type="text"
            disabled={isSubmitting}
            name="email"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={formik.handleChange("email")}
            value={formik.values.email}
            helperText={!!(formik.touched.email && formik.errors.email) && formik.errors.email}
            error={!!(formik.touched.email && formik.errors.email)}
          />
        </FormControl>

        <Box marginY={1} display={"flex"}>
          <Checkbox
            checked={isReqConfirmed}
            onChange={(event) => setIsReqConfirmed(event.target.checked)}
            checkedIcon={<Check/>}
            icon={<Check visibility={"hidden"}/>}
          />
          <Typography variant={"body2"} fontSize={10}>
            Oświadczam, iż zapoznałem się z{' '}
            <Link
              variant="body2"
              fontSize={10}
              onClick={() => openLink("https://www.badanie-opinii.pl/regulamin")}
            >
              Regulaminem Serwisu internetowego i Panelu
            </Link>{' '}
            oraz akceptuję jego postanowienia.
          </Typography>
        </Box>

        <Box display={"flex"}>
          <Checkbox
            checked={isRodoConfirmed}
            onChange={(event) => setIsRodoConfirmed(event.target.checked)}
            checkedIcon={<Check/>}
            icon={<Check visibility={"hidden"}/>}
          />
          <Typography variant={"body2"} fontSize={10}>
            Wyrażam zgodę na przetwarzanie przez Biostat Sp. z o.o., z siedzibą ul.
            Kowalczyka 17, 44-206 Rybnik, NIP: 6423125404, REGON: 241544443, KRS:
            0000351860, moich danych osobowych w celach związanych z korzystaniem z
            serwisu internetowego/Panelu www.badanie-opinii.pl w zgodzie i według zasad
            określonych w Polityce prywatności.
          </Typography>
        </Box>

        <Box marginTop={3} display={"flex"} gap={1} flexDirection={"column"}>
          {!isRequesting ? (
            <>
              <Button type={"submit"} disabled={!isRodoConfirmed || !isReqConfirmed} fullWidth variant="contained">{t("register")}</Button>
              <Button fullWidth onClick={() => navigate("/")}>{t("login")}</Button>
            </>
          ) : (
            <Box paddingY={2} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress/>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default RegisterForm;