import React from 'react';
import { Backdrop, Box, Button, Divider, Fade, Modal, Typography } from "@mui/material";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24
};

const InviteFriendsModal = ({recommendationLink, isOpen, onClose}) => {
  const {t} = useTranslation();

  const handleCopy = (): void => {
    copy(recommendationLink).then(r => (
      toast.success(t('dashboard:linkCoppied'), {position: "bottom-center"})
    ));

    setTimeout(() => {
      onClose();
    }, 200);
  };

  const handleShare = async (): Promise<void> => {
    const title = 'Your Share Title';
    const text = 'Your share message';
    const url = recommendationLink;

    try {
      if (navigator.share) {
        await navigator.share({ title, text, url });
        console.log('Shared successfully');
      } else {
        console.log('Web Share API not supported');
      }
    } catch (error: any) {
      console.error('Error sharing:', error.message);
    } finally {
      onClose();
    }
  };


  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Box padding={3}>
              <Typography variant={"body1"} fontWeight={"bold"} textAlign={"center"} color={'#009688'}>Zaproś znajomych</Typography>
              <Typography variant={"body1"} textAlign={"center"}>Podziel się ze znajomymi i odbierz dodatkowe punkty</Typography>
            </Box>
            <Divider />
            <Box display={"flex"}>
              <Button size={"large"} sx={{width: "50%"}} variant="text" onClick={handleCopy}>
                Skopiuj
              </Button>
              <Button size={"large"} sx={{width: "50%"}} variant="text" onClick={handleShare}>
                Udostępnij
              </Button>
            </Box>
            <Box>
              <Button fullWidth variant="text" onClick={onClose}>
                Anuluj
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default InviteFriendsModal;