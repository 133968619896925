import React from 'react';
import { FormControlLabel, Switch, Typography } from "@mui/material";

const ProfileFieldCheckbox = ({value, field, onChange}) => {
  const options = field.options;

  const handleChange = (name, newValue): void => {
    const newValues = value;
    newValues[name] = newValue ? 1 : 0;
    onChange(field.name, newValues);
  }

  return (
    <>
      <Typography>{field.label}</Typography>
      {options.map((option: any, index: number) => {
        if (typeof value === 'undefined') {
          return null;
        }

        const name = option.value;

        return (
          <FormControlLabel
            sx={{width: "fit-content"}}
            key={index}
            onChange={() => handleChange(name, !value[name])}
            control={
              <Switch
                onChange={(event) => handleChange(name, event.target.checked)}
                checked={value[name] > 0}
              />
            }
            label={option.label}
          />
        );
      })}
    </>
  );
};

export default ProfileFieldCheckbox;