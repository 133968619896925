import React from 'react';
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ProfileFieldCalendar = ({value, field, onChange}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={field.label}
        value={value ? moment(value) : null}
        onChange={(value) => {
          onChange(field.name, value?.format("YYYY-MM-DD"));
        }}
        slots={{
          textField: (params) => <TextField variant='standard' {...params} />
        }}
      />
    </LocalizationProvider>
  );
};

export default ProfileFieldCalendar;