import { SURVEY_SUCCESS, SURVEY_ERROR, SURVEY_REQUEST } from './SurveyTypes'
import API from '../../utils/api'
import { toast } from "react-toastify";

export const surveySuccess = (payload) => ({
  type: SURVEY_SUCCESS,
  payload,
});

export const surveyRequest = () => ({
  type: SURVEY_REQUEST,
});

export const surveyError = (error) => ({
  type: SURVEY_ERROR,
  payload: error,
});

export const surveyAction = () => {
  return async (dispatch) => {
    dispatch(surveyRequest());
    await API.Instance.get('/surveys').then((response: any) => {
      if (!API.isValidResponse(response)) {
        const errorMessage = response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

        dispatch(surveyError(errorMessage));
        toast.error(errorMessage, {position: "bottom-center"});
        return;
      }

      const {data} = response.data;

      dispatch(surveySuccess(data.surveys));
    })
  }
}
