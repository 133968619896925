import React from 'react';
import { TextField } from "@mui/material";

const ProfileFieldText = ({value, field, onChange}) => {

  return (
    <TextField fullWidth variant={"standard"} value={value || ""} defaultValue={value} label={field.label} onChange={onChange(field.name)}/>
  );
};

export default ProfileFieldText;