import { GROUP_FIELDS_ERROR, GROUP_FIELDS_REQUEST, GROUP_FIELDS_SUCCESS, GROUP_FIELDS_CLEAR } from './GroupFieldsType'
import API from '../../utils/api'

export const groupFieldsRequest = () => ({
  type: GROUP_FIELDS_REQUEST,
});

export const groupFieldsSuccess = (payload) => ({
  type: GROUP_FIELDS_SUCCESS,
  payload,
});

export const groupFieldsError = (error) => ({
  type: GROUP_FIELDS_ERROR,
  payload: error,
});

export const clearGroupFields = () => ({
  type: GROUP_FIELDS_CLEAR,
});

export const requestGroupFields = (groupId) => {
  return (dispatch) => {
    dispatch(groupFieldsRequest())
    API.Instance.get('/profile_fields', {profile_group: groupId}).then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(groupFieldsError(response.data.error));
        return;
      }

      dispatch(groupFieldsSuccess(response.data.data));
    })
  }
}

export const submitGroupFields = (fields): any => {
  return async (dispatch) => {
    return await API.Instance.post('/set_profile_fields', {items: JSON.stringify(fields)})
      .then((response: any) => {
        if (!API.isValidResponse(response)) {
          throw response.data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";
        }

        return response.data.message;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
