import { EXCHANGE_ERROR, EXCHANGE_REQUEST, EXCHANGE_SUCCESS } from './ExchangeTypes';
import API from '../../utils/api';

export const exchangeSuccess = (exchanges_products: any) => ({
  type: EXCHANGE_SUCCESS,
});

export const exchangeRequest = () => ({
  type: EXCHANGE_REQUEST,
});

export const exchangeError = (error) => ({
  type: EXCHANGE_ERROR,
  payload: error,
});

export const exchangeAction = (values) => {
  return async (dispatch: any) => {
    dispatch(exchangeRequest());
    await API.Instance.post('/set_exchange', values)
      .then((response: any) => {
        if (!API.isValidResponse(response)) {
          const errorMessage = response.data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

          dispatch(exchangeError(errorMessage));
          throw (errorMessage);
        }

        const {exchanges_products} = response.data.data;

        dispatch(exchangeSuccess(exchanges_products));
      })
      .catch(error => {
        console.error(error);
      });
  };
};
