import { combineReducers } from 'redux'
import AuthReducer from './Auth/AuthReducer'
import RegisterReducer from './Register/RegisterReducer'
import UserReducer from './User/UserReducer'
import SurveyReducer from './Survey/SurveyReducer'
import HistoryReducer from './History/HistoryReducer'
import ExchangeListReducer from './ExchangeList/ExchangeListReducer'
import ExchangeReducer from './Exchange/ExchangeReducer'
import ProfileReducer from './Profile/ProfileReducer'
import GroupFieldsReducer from './GroupFields/GroupFieldsReducer'

export default combineReducers({
  auth: AuthReducer,
  register: RegisterReducer,
  user: UserReducer,
  surveys: SurveyReducer,
  history: HistoryReducer,
  exchangeList: ExchangeListReducer,
  exchange: ExchangeReducer,
  profile: ProfileReducer,
  groupFields: GroupFieldsReducer,
})
