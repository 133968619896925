import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box, Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  IconButton,
  List, ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import * as ExchangeListActions from '../../modules/ExchangeList/ExchangeListAction';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ExchangeModal from "../../components/ExchangeModal";
import * as UserActions from "../../modules/User/UserAction";

const Exchange = () => {
  const {t} = useTranslation("exchange");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exchange = useSelector((state: any) => state.exchangeList);
  const isFetching = useSelector((state: any) => state.exchangeList.isFetching);
  const user = useSelector((state: any) => state.user);
  const [openExchangeModal, setOpenExchangeModal] = useState(false);
  const [exchangeId, setExchangeId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItemForDialog, setCurrentItemForDialog] = useState(null);
  const [openPointsDialog, setOpenPointsDialog] = useState(false);
  const [exchangeValues, setExchangeValues] = useState({
    user_id: user.id,
    firstname: user.name,
    lastname: user.lastname,
    city: '',
    street: '',
    home: '',
    zip: '',
    account_number: '',
    pesel: '',
    nip: '',
  });
  const listRef = useRef<any>(null);
  const firstUpdate = useRef(true);
  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      const {absY} = eventData;
      const threshold = 50;
      const isOutsideList = absY < -threshold || absY > threshold;
      const isScrolledToTop = listRef.current?.scrollTop === 0;

      if (isOutsideList && isScrolledToTop) {
        fetchOptions();
      }
    }
  });
  const fetchOptions = useCallback(() => {
    dispatch(ExchangeListActions.exchangeListAction())
  }, [dispatch]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      fetchOptions();

      return;
    }
  }, [fetchOptions]);

  const handleExchangeModalOpen = (): void => setOpenExchangeModal(true);

  const handleExchangeModalClose = (event: Object, reason: string): void => {
    if (reason !== 'backdropClick') {
      setOpenExchangeModal(false);
    }
  };

  const refPassthrough = (el): void => {
    handlers.ref(el);

    listRef.current = el;
  }

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleOpenPointsDialog = (): void => {
    setOpenPointsDialog(true);
  };

  const handleClosePointsDialog = (): void => {
    setOpenPointsDialog(false);
  };

  const onPress = (item: any) => {
    dispatch(UserActions.userAction());

    if (item.points > user.points) {
      return notEnoughPointsAlert()
    }

    if (user.exchange_last_address) {
      askForLastExchangeAddress(item)
    } else {
      clearExchangeAddress(item)
    }
  }

  const notEnoughPointsAlert = (): void => {
    handleOpenPointsDialog();
  }

  const clearExchangeAddress = (item: any): void => {
    setExchangeId(item.id);
    setExchangeValues({
      ...exchangeValues,
      firstname: user.name,
      lastname: user.lastname,
      city: '',
      street: '',
      home: '',
      zip: '',
      account_number: '',
      pesel: '',
      nip: '',
    });
    handleExchangeModalOpen();
  }

  const askForLastExchangeAddress = (item: any): void => {
    setCurrentItemForDialog(item);
    handleOpenDialog();
  }

  const loadLastExchangeAddress = (item: any): void => {
    setExchangeId(item.id);

    const {exchange_last_address} = user;

    setExchangeValues({
      ...exchangeValues,
      account_number: exchange_last_address.account_number,
      city: exchange_last_address.city,
      firstname: exchange_last_address.firstname,
      home: exchange_last_address.home,
      lastname: exchange_last_address.lastname,
      nip: exchange_last_address.nip,
      pesel: exchange_last_address.pesel,
      street: exchange_last_address.street,
      zip: exchange_last_address.zip,
    });

    handleExchangeModalOpen();
  }

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/dashboard")}}><ArrowBackIosNewIcon/></IconButton>,
        text: "Wymień punkty"
      }}>
        <ExchangeModal exchangeFormikValues={{
          ...exchangeValues,
          id: exchangeId,
        }} isOpen={openExchangeModal} onClose={handleExchangeModalClose}/>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('useLastExchangeAddress')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={() => {
              handleCloseDialog();
              clearExchangeAddress(currentItemForDialog);
            }} autoFocus>{t('text:no')}</Button>
            <Button fullWidth onClick={() => {
              handleCloseDialog();
              loadLastExchangeAddress(currentItemForDialog);
            }}>
              {t('text:yes')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPointsDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('cantExchange')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('exchange:notEnoughPoints')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fullWidth onClick={handleClosePointsDialog}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {isFetching && (
          <Box paddingY={2} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress/>
          </Box>
        )}

        {!exchange.exchangeList ? (
          <>
            <Typography>{t("empty")}</Typography>
          </>
        ) : (
          <>
            <Box {...handlers} ref={refPassthrough} height={"100%"}>
              <List>
                {exchange.exchangeList.map((res) => (
                  <ListItem key={res.id} disablePadding>
                    <ListItemButton divider onClick={() => onPress(res)}>
                      <ListItemText
                        primary={`${t('exchange')} ${res.points}${t('pkt')}`}
                        primaryTypographyProps={{ color: '#009688' }}
                        secondary={res.name}
                      />
                      <ArrowCircleRightIcon sx={{ color: '#009688' }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}
      </ToolbarWrapper>
    </>
  );
};

export default Exchange;