import { HISTORY_REQUEST, HISTORY_ERROR, HISTORY_SUCCESS } from './HistoryTypes';

const initialState = {
  isFetching: false,
  surveys: [],
  error: '',
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case HISTORY_REQUEST:
      return {
        ...state,
        error: '',
        isFetching: true,
      };
    case HISTORY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case HISTORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        surveys: action.payload,
      };
    default:
      return state;
  }
};

export default historyReducer;