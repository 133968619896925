import React from 'react';
import { CircularProgress, Container } from "@mui/material";
import styles from './styles.module.scss';

const Loader = () => {
  return (
    <Container sx={{display: "flex"}} className={styles.loaderContainer}>
      <CircularProgress/>
    </Container>
  );
};

export default Loader;
