import React from 'react';
import ProfileFieldText from "../../components/ui/ProfileFieldText";
import ProfileFieldSelect from "../../components/ui/ProfileFieldSelect";
import ProfileFieldCheckbox from "../../components/ui/ProfileFieldCheckbox";
import ProfileFieldCalendar from "../../components/ui/ProfileFieldCalendar";

const ProfileField = ({field, value, onChange, setFieldValue}) => {
  return (
    <>
      {field.type === 'text' && (
        <ProfileFieldText value={value} field={field} onChange={onChange}/>
      )}
      {(field.type === 'select' && typeof value !== "undefined") && (
        <ProfileFieldSelect value={value || ""} field={field} onChange={setFieldValue}/>
      )}
      {field.type === 'checkbox' && (
        <ProfileFieldCheckbox value={value} field={field} onChange={setFieldValue}/>
      )}
      {field.type === 'calendar' && (
        <ProfileFieldCalendar value={value} field={field} onChange={setFieldValue}/>
      )}
    </>
  );
};

export default ProfileField;