import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import Visibility from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleSignin from 'react-apple-signin-auth';
import {
  Box, Button, CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { VisibilityOff } from "@mui/icons-material";
import { Colors } from "../../consts/colors";
import { useFormik } from "formik";
import I18n from "i18next";
import { object, string } from "yup";
import { ILoginFormProps } from "./types";
import { ILoginValues } from "../../containers/Login/types";

const validationSchema = object().shape({
  login: string().required(I18n.t("login:loginEmailEmptyError")),
  password: string().required(I18n.t("login:passwordEmptyError")),
});

const LoginForm = (props: ILoginFormProps) => {
  const {isSubmitting, signIn, handleInputFocus, handleInputBlur, email, signInFB, signInApple} = props;
  const {t} = useTranslation('login');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      login: email,
      password: "",
      facebook: null,
      apple: null
    },
    onSubmit: (values: ILoginValues): void => {
      signIn(values);
    }
  });

  const handleClickShowPassword = (): void => setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  const isIOS = (): boolean => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <Box marginTop={3} width={"100%"}>
      <form onSubmit={formik.handleSubmit}>
        <Box marginBottom={1}>
          <FormControl fullWidth variant="standard">
            <TextField
              autoComplete="username"
              variant="standard"
              name="login"
              type="text"
              disabled={isSubmitting}
              label={t("loginLabel")}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              helperText={!!(formik.touched.login && formik.errors.login) && formik.errors.login}
              onChange={formik.handleChange("login")}
              value={formik.values.login}
              error={!!(formik.touched.login && formik.errors.login)}
            />
          </FormControl>
        </Box>

        <FormControl fullWidth sx={{marginY: 1}} variant="standard">
          <TextField
            label={t("passwordLabel")}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            variant={"standard"}
            disabled={isSubmitting}
            name="password"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={formik.handleChange("password")}
            value={formik.values.password}
            helperText={!!(formik.touched.password && formik.errors.password) && formik.errors.password}
            error={!!(formik.touched.password && formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{color: Colors.colorPrimary}}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box marginTop={3} display={"flex"} gap={1} flexDirection={"column"}>
        {!isSubmitting ? (
          <>
            <Button type="submit" fullWidth variant="contained">{t("btn")}</Button>
            <FacebookLogin
              version="19.0"
              disableMobileRedirect={true}
              isMobile={true}
              appId={process.env.REACT_APP_FACEBOOK_ID || ""}
              fields="id, name, first_name, last_name, email"
              render={(props) => (
                <Button onClick={props.onClick} className={`${styles.btn} ${styles.facebook}`} fullWidth variant="contained"><FacebookIcon/>ZALOGUJ PRZEZ FACEBOOK</Button>
              )}
              callback={(res) => {
                signInFB(res);
              }}
            />
            {isIOS() && (
              <AppleSignin
                authOptions={{
                  clientId: 'com.badanieopinii.pwa',
                  scope: 'email name',
                  redirectURI: 'https://pwa.badanie-opinii.pl',
                  state: '',
                  nonce: 'nonce',
                  usePopup: false,
                }}
                render={(props) => (
                  <Button {...props} className={`${styles.btn} ${styles.apple}`} fullWidth variant="contained"><AppleIcon/>Continue with Apple</Button>
                )}
                onError={() => {}}
                onSuccess={(res) => {
                  const resUser = res?.user;
                  const token = res?.authorization?.id_token;
                  const decoded = jwtDecode(token);
                  let email, firstname, lastname, id;

                  if (resUser) {
                    email = resUser.email;
                    firstname = resUser.name.firstName;
                    lastname = resUser.name.lastName;
                  } else {
                    email = decoded['email'];
                  }

                  id = decoded['sub'];

                  signInApple({email: email, id: id, first_name: firstname, last_name: lastname});
                }}
                uiType={"dark"}
              />
            )}
            <Button onClick={() => navigate("register")} fullWidth>{t("registerBtn")}</Button>
          </>
        ) : (
            <Box paddingY={2} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <CircularProgress/>
            </Box>
        )}
        </Box>
      </form>
    </Box>
  );
};

export default LoginForm;