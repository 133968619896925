import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AboutApp = () => {
  const {t} = useTranslation('about');
  const navigate = useNavigate();
  const [click, setClick] = useState(0);

  useEffect(() => {
    click === 5 && toast(t('about:appSecretText'), {position: "bottom-center"});
  }, [click, t]);

  const onVersionHandle = (): void => {
    setClick(click + 1);
  }

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/settings")}}><ArrowBackIosNewIcon/></IconButton>,
        text: "O aplikacji"
      }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton divider>
              <ListItemText primary={t('about:appInfoTitle')} secondary={
                <>
                  &copy; {t('about:appInfoText')}
                </>
              }/>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton divider onClick={onVersionHandle}>
              <ListItemText primary={t('about:appVersionTitle')} secondary={"1.0.0"}/>
            </ListItemButton>
          </ListItem>
        </List>
      </ToolbarWrapper>
    </>
  );
};

export default AboutApp;