import { EXCHANGE_ERROR, EXCHANGE_REQUEST, EXCHANGE_SUCCESS } from './ExchangeTypes'

const initialState = {
  isFetching: false,
  error: ''
}

const exchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_REQUEST:
      return {
        ...state,
        error: '',
        isFetching: true,
      }
    case EXCHANGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    case EXCHANGE_SUCCESS:
      return {
        error: '',
        isFetching: false,
      }
    default:
      return state
  }
};

export default exchangeReducer;
