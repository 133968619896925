import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './utils/translations/index';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import reducers from "./modules/reducers";
import { LocalStorageProvider } from "./services/LocalStorageService";
import { BrowserRouter } from "react-router-dom";

const logger = createLogger({});
// @ts-ignore
const MiddlewareStore = applyMiddleware(thunk, logger)(createStore);
const store = MiddlewareStore(reducers);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalStorageProvider>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </LocalStorageProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
