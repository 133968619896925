import { create } from 'apisauce'
import base64 from 'base-64';

const RESPONSE_STATUS_OK = 1;

const Api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: +process.env.REACT_APP_API_TIMEOUT!,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/vnd.github.v3+json',
    Authorization: `Basic ${base64.encode(`${process.env.REACT_APP_API_USER}${process.env.REACT_APP_API_PASSWORD}`)}`
  },
});

const authTokenTransformer = async (request: any) => {
  const tokenFromStorage = localStorage.getItem("token");
  const token = tokenFromStorage?.replace(/^"|"$/g, '');
  request.headers['token'] = `${token}`;
};

Api.addAsyncRequestTransform(authTokenTransformer)

const checkIfAuthenticated = async (): Promise<{isAuthenticated: boolean, token: string | undefined}> => {
  const response = await Api.get('/check_token');
  const tokenFromStorage = localStorage.getItem("token");
  const token = tokenFromStorage?.replace(/^"|"$/g, '');

  return {
    token,
    isAuthenticated: isValidResponse(response)
  };
};

const isValidResponse = (response: any): boolean => {
  const status = (response && response.data && response.data.status) || 0;

  return status === RESPONSE_STATUS_OK;
};

const apiConfig = {
  checkIfAuthenticated,
  isValidResponse,
  Instance: Api
};

export default apiConfig;
