import React from 'react';
import styles from './styles.module.scss';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Colors } from "../../../consts/colors";
import { DefaultToolbarProps } from "./types";
import logoImage from "../../../assets/images/logo_login.png";

const DefaultToolbar: React.FC<DefaultToolbarProps> = ({
  leftItems = <></>,
  rightItems = <></>,
  hasLogo = false,
  text = ""
}) => {
  return (
    <AppBar position="fixed" sx={{background: Colors.white, height: 56}}>
      <Toolbar className={styles.toolbarWrapper} sx={{gap: 1, whiteSpace: "nowrap"}}>
        <Box flex={1} justifyContent={"flex-start"} display={"flex"}>
          {leftItems}
        </Box>

        {hasLogo ? (
          <img className={styles.toolbarLogo} src={logoImage} alt={"App logo"}/>
        ) : (
          <Typography className={styles.toolbarText} variant="h5">
            {text}
          </Typography>
        )}

        <Box flex={1} justifyContent={"flex-end"} display={"flex"}>
          {rightItems}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default DefaultToolbar;
