import React from 'react';
import styles from './styles.module.scss';
import logoImage from "../../assets/images/logo_login.png";
import LoginForm from "../../components/LoginForm";
import { Container } from '@mui/material';
import useInputFocus from "../../hooks/useInputFocus";
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from "../../services/LocalStorageService";
import * as AuthActions from '../../modules/Auth/AuthAction';
import { useLocation } from "react-router-dom";
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from "react-facebook-login";

const Login = () => {
  const location = useLocation();
  const {inputActive, handleInputFocus, handleInputBlur} = useInputFocus();
  const dispatch = useDispatch();
  const localStorageFunctions = useLocalStorage();
  let isSubmiting = useSelector((state: any) => state.auth.isSubmitting);

  const signIn = (values): void => {
    dispatch(AuthActions.authAction(values.login, values.password, localStorageFunctions));
  };

  const signInFB = (values: ReactFacebookLoginInfo | ReactFacebookFailureResponse): void => {
    dispatch(AuthActions.authFacebookAction(values, localStorageFunctions));
  };

  const signInApple = (values): void => {
    dispatch(AuthActions.authAppleAction(values, localStorageFunctions));
  };

  return (
    <>
      <Container sx={{display: "flex"}} className={styles.loginContainer}>
        <img className={inputActive ? styles.loginLogoHidden : styles.loginLogo} src={logoImage} alt={"App logo"}/>
        <LoginForm
          email={location.state?.email || ""}
          isSubmitting={isSubmiting}
          signIn={signIn}
          signInFB={signInFB}
          signInApple={signInApple}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          inputActive={inputActive}
        />
      </Container>
    </>
  );
};

export default Login;