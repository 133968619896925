import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_CLOSE_PROFILE_MODAL, USER_TOKEN } from './UserType'

const initialState = {
  id: 0,
  name: '',
  lastname: '',
  email: '',
  add_date: '',
  points: 0,
  friends: 0,
  recommendation_link: '',
  points_incoming: 0,
  points_outgoing: 0,
  active_surveys: 0,
  error: '',
  isFetching: false,
  fetchIsReady: false,
  showProfileModal: true,
  token: {
    token: '',
    os: '',
  },
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_CLOSE_PROFILE_MODAL:
      return {
        ...state,
        showProfileModal: false,
      }
    case USER_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        fetchIsReady: false,
      }
    case USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        fetchIsReady: false,
      }
    case USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: '',
        isFetching: false,
        fetchIsReady: true,
      }
    case USER_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    default:
      return state
  }
};

export default userReducer;