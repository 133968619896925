import React from 'react';
import { Backdrop, Box, Button, Divider, Fade, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24
};

const ProfileModal = ({isOpen, onClose, activeSurveys}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate("/profile");
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Box padding={3}>
              <Typography variant={"body1"} fontWeight={"bold"} textAlign={"center"} color={'#009688'}>{t('dashboard:profileModalTitle')}</Typography>
              <Typography variant={"body1"} textAlign={"center"}>
                {t('dashboard:profileModalTextBefore')}
                {activeSurveys}
                {t('dashboard:profileModalTextAfter')}
              </Typography>
            </Box>
            <Divider />
            <Box display={"flex"}>
              <Button sx={{flexGrow: 1}} size={"large"} variant="text" onClick={onClose}>
                {t('dashboard:profileModalClose')}
              </Button>
              <Button sx={{flexGrow: 1}} size={"large"} variant="text" onClick={handleGoToProfile}>
                {t('dashboard:profileModalAction')}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ProfileModal;