import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText, Paper,
  Popover, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Colors } from "../../consts/colors";
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import logoImage1 from "../../assets/images/wymien_icon.png";
import logoImage2 from "../../assets/images/ankiety_icon.png";
import logoImage3 from "../../assets/images/historia_icon.png";
import logoImage4 from "../../assets/images/link_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../services/LocalStorageService";
import * as AuthActions from '../../modules/Auth/AuthAction';
import * as UserActions from '../../modules/User/UserAction';
import * as ProfileActions from '../../modules/Profile/ProfileAction';
import * as SurveyActions from '../../modules/Survey/SurveyAction';
import * as HistoryActions from '../../modules/History/HistoryAction';
import InviteFriendsModal from "../../components/InviteFriendsModal";
import ProfileModal from "../../components/ProfileModal";

const Dashboard = () => {
  const {t} = useTranslation("dashboard");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const id = open ? 'simple-popover' : undefined;
  const localStorageFunctions = useLocalStorage();
  const activeSurveys = useSelector((state: any) => state.user.active_surveys);
  const showProfileModal = useSelector((state: any) => state.user.showProfileModal);
  const points = useSelector((state: any) => state.user.points);
  const friends = useSelector((state: any) => state.user.friends);
  const user = useSelector((state: any) => state.user);
  const recommendationLink = useSelector((state: any) => state.user.recommendation_link);
  const progress = useSelector((state: any) => state.profile.progress);
  const progressIsFetching = useSelector((state: any) => state.profile.progressIsFetching);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      dispatch(UserActions.userAction());
      dispatch(ProfileActions.requestProfileProgress());
      dispatch(SurveyActions.surveyAction());
      dispatch(HistoryActions.historyAction());

      return;
    }
  }, [dispatch]);

  useEffect(() => {
    if (!progressIsFetching && progress < 50 && activeSurveys > 0 && showProfileModal) {
      handleProfileModalOpen();
    }
  }, [progress, activeSurveys, showProfileModal, progressIsFetching]);

  const handleShareModalOpen = (): void => setOpenShareModal(true);

  const handleShareModalClose = (): void => setOpenShareModal(false);

  const handleProfileModalOpen = (): void => setOpenProfileModal(true);

  const handleProfileModalClose = (): void => {
    dispatch(UserActions.userCloseProfileModal());
    setOpenProfileModal(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    dispatch(AuthActions.logoutAction(localStorageFunctions));
  };

  return (
    <>
      <ToolbarWrapper
        hasToolbar={true}
        toolbarConfig={{
          hasLogo: true,
          rightItems: (
            <>
              <IconButton aria-describedby={id} style={{color: Colors.colorPrimary}}
                          onClick={(event) => handleClick(event)}>
                <MenuIcon/>
              </IconButton>

              <Popover
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => {navigate("/profile")}}>
                      <ListItemText primary="Twój profil"/>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton divider onClick={() => {navigate("/settings")}}>
                      <ListItemText primary="Ustawienia"/>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout}>
                      <ListItemText primary="Wyloguj"/>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </>
          ),
        }}
      >
        <InviteFriendsModal recommendationLink={recommendationLink} isOpen={openShareModal} onClose={handleShareModalClose}/>
        <ProfileModal isOpen={openProfileModal} onClose={handleProfileModalClose} activeSurveys={activeSurveys}/>

        <Box display={"flex"} flexDirection={"column"} gap={1} justifyContent={"center"} alignItems={"center"}
             paddingY={5}>
          <Avatar sx={{bgcolor: "#87CDC7", width: 64, height: 64}}>
            <PersonIcon fontSize={"large"} sx={{color: "#009688"}}/>
          </Avatar>

          <Typography variant={"h6"} sx={{color: "#009688", fontWeight: "bold"}}>{user.name} {user.lastname}</Typography>
        </Box>

        <Box bgcolor={"rgba(204, 204, 204, .2)"} paddingY={4}>
          <Box display={"flex"} gap={2} justifyContent={"center"} alignItems={"center"}>
            <Box flexDirection={"column"} display={"flex"} gap={.5} justifyContent={"center"} alignItems={"center"}>
              <Typography variant={"h6"} fontWeight={"500"} color={Colors.colorPrimary}>{activeSurveys}</Typography>
              <Typography variant={"body2"}>{t("statsAvailableSurveyslabel")}</Typography>
            </Box>
            <Box flexDirection={"column"} display={"flex"} gap={.5} justifyContent={"center"} alignItems={"center"}>
              <Typography variant={"h6"} fontWeight={"500"} color={Colors.colorPrimary}>{points}</Typography>
              <Typography variant={"body2"}>{t("statsMyPointslabel")}</Typography>
            </Box>
            <Box flexDirection={"column"} display={"flex"} gap={.5} justifyContent={"center"} alignItems={"center"}>
              <Typography variant={"h6"} fontWeight={"500"} color={Colors.colorPrimary}>{friends}</Typography>
              <Typography variant={"body2"}>{t("statsFriendslabel")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box bgcolor={"rgba(204, 204, 204, .2)"} height={"100%"} padding={4} paddingTop={0} overflow={"auto"}>
          <Grid container spacing={2}>
            <Grid xs={6} sx={{aspectRatio: "1 / 1"}} item>
              <Paper onClick={() => navigate("/survey")} className={styles.squareWrapper} square={false} sx={{width: "100%", height: "100%"}}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <img className={styles.iconLogo} src={logoImage2}
                       alt={"Icon"}/>
                  <Typography color={Colors.colorPrimary} variant={"body2"} textAlign={"center"}>{t("navSurveysLabel")}</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid xs={6} sx={{aspectRatio: "1 / 1"}} item>
              <Paper onClick={() => navigate("/historyPoints")} className={styles.squareWrapper} square={false} sx={{width: "100%", height: "100%", padding: 1}}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <img className={styles.iconLogo} src={logoImage3}
                       alt={"Icon"}/>
                  <Typography color={Colors.colorPrimary} variant={"body2"} textAlign={"center"}>{t("navPointsHistoryLabel")}</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid xs={6} sx={{aspectRatio: "1 / 1"}} item>
              <Paper onClick={() => navigate("/exchange")} className={styles.squareWrapper} square={false} sx={{width: "100%", height: "100%"}}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <img className={styles.iconLogo} src={logoImage1}
                       alt={"Icon"}/>
                  <Typography color={Colors.colorPrimary} variant={"body2"} textAlign={"center"}>{t("navPointsExchangeLabel")}</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid xs={6} sx={{aspectRatio: "1 / 1"}} item>
              <Paper onClick={handleShareModalOpen} className={styles.squareWrapper} square={false} sx={{width: "100%", height: "100%"}}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <img className={styles.iconLogo} src={logoImage4}
                       alt={"Icon"}/>
                  <Typography color={Colors.colorPrimary} variant={"body2"} textAlign={"center"}>{t("navReferralLinkLabel")}</Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ToolbarWrapper>
    </>
  );
};

export default Dashboard;