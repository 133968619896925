import {
  PROFILE_GROUPS_ERROR,
  PROFILE_GROUPS_REQUEST,
  PROFILE_GROUPS_SUCCESS,
  PROFILE_PROGRESS_ERROR,
  PROFILE_PROGRESS_REQUEST,
  PROFILE_PROGRESS_SUCCESS,
  PROFILE_REMOVE_SUCCESS,
  PROFILE_REMOVE_REQUEST,
  PROFILE_REMOVE_ERROR,
  PROFILE_REMOVE_CLEAR
} from './ProfileType'
import API from '../../utils/api'
import { logoutAction } from '../Auth/AuthAction';
import { LocalStorageFunctions } from "../../services/LocalStorageService/types";

export const profileProgressRequest = () => ({
  type: PROFILE_PROGRESS_REQUEST
});

export const profileProgressSuccess = (payload) => ({
  type: PROFILE_PROGRESS_SUCCESS,
  payload
});

export const profileProgressError = (error) => ({
  type: PROFILE_PROGRESS_ERROR,
  payload: error
});

export const profileGroupsRequest = () => ({
  type: PROFILE_GROUPS_REQUEST
});

export const profileGroupsSuccess = (payload) => ({
  type: PROFILE_GROUPS_SUCCESS,
  payload
});

export const profileGroupsError = (error) => ({
  type: PROFILE_GROUPS_ERROR,
  payload: error
});

export const profileRemoveClear = () => ({
  type: PROFILE_REMOVE_CLEAR
});
export const profileRemoveRequest = () => ({
  type: PROFILE_REMOVE_REQUEST
});

export const profileRemoveSuccess = (payload) => ({
  type: PROFILE_REMOVE_SUCCESS,
  payload
});

export const profileRemoveError = (error) => ({
  type: PROFILE_REMOVE_ERROR,
  payload: error
});

export const requestProfileProgress = () => {
  return (dispatch: any) => {
    dispatch(profileProgressRequest())
    API.Instance.get('/profile_progress').then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(profileProgressError(response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później."));
        return;
      }

      dispatch(profileProgressSuccess(response.data?.data));
    })
  }
};

export const requestProfileGroups = () => {
  return (dispatch: any) => {
    dispatch(profileGroupsRequest())
    API.Instance.get('/profile_groups').then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(profileGroupsError(response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później."));
        return;
      }

      dispatch(profileGroupsSuccess(response.data.data))
    })
  }
};

export const requestRemoveProfile = (localStorageFunctions: LocalStorageFunctions) => {
  return (dispatch: any) => {
    dispatch(profileRemoveRequest())
    API.Instance.post('/remove_account').then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(profileRemoveError(response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później."));
        return;
      }

      dispatch(profileRemoveSuccess(response.data?.msg || ""));
      dispatch(logoutAction(localStorageFunctions));
    })
  }
};

export const requestRemoveProfileClear = () => {
  return (dispatch: any) => {
    dispatch(profileRemoveClear());
  }
};