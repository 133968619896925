import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import logoImage from "../../assets/images/logo_login.png";
import { Container} from '@mui/material';
import useInputFocus from "../../hooks/useInputFocus";
import { useDispatch, useSelector } from 'react-redux';
import RegisterForm from "../../components/RegisterForm";
import { IRegisterValues } from "./types";
import * as RegisterActions from '../../modules/Register/RegisterAction';
import { useNavigate } from "react-router-dom";

const Register = () => {
  const {inputActive, handleInputFocus, handleInputBlur} = useInputFocus();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector((state: any) => state.register.email);

  useEffect(() => {
    if (email && email !== '') {
      navigate('/', {
        state: {
          email: email,
        }
      });

      dispatch(RegisterActions.registerClear());
    }
  }, [dispatch, email, navigate]);

  const signIn = (values: IRegisterValues): void => {
    dispatch(RegisterActions.registerAction(values.email, values.name, values.lastname));
  };

  return (
    <>
      <Container sx={{display: "flex"}} className={styles.registerContainer}>
        <img className={inputActive ? styles.registerLogoHidden : styles.registerLogo} src={logoImage}
             alt={"App logo"}/>
        <RegisterForm
          isSubmitting={false}
          signIn={signIn}
          handleInputFocus={handleInputFocus}
          handleInputBlur={handleInputBlur}
          inputActive={inputActive}
        />
      </Container>
    </>
  );
};

export default Register;