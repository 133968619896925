import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_CLOSE_PROFILE_MODAL, USER_TOKEN } from './UserType'
import API from '../../utils/api';

export const userSuccess = (payload) => ({
  type: USER_SUCCESS,
  payload
});

export const userRequest = () => ({
  type: USER_REQUEST
});

export const userError = (error) => ({
  type: USER_ERROR,
  payload: error
});

export const userCloseProfileModal = () => ({
  type: USER_CLOSE_PROFILE_MODAL
});

export const userToken = (token) => ({
  type: USER_TOKEN,
  token
});

export const userAction = () => {
  return (dispatch) => {
    dispatch(userRequest());
    API.Instance.get('/data').then((response: any) => {
      if (!API.isValidResponse(response)) {
        dispatch(userError(response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później."));
        return;
      }

      const {user} = response.data.data;

      dispatch(
        userSuccess({
          id: Number(user.id),
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          add_date: user.add_date,
          points: Number(user.points),
          friends: Number(user.friends),
          recommendation_link: user.recommendation_link,
          points_incoming: Number(user.points_incoming),
          points_outgoing: Number(user.points_outgoing),
          active_surveys: Number(user.active_surveys),
          exchange_last_address: user.exchange_last_address,
          error: '',
        })
      );
    })
  }
}
