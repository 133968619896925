export const Colors = {
  colorPrimary: "#009688",
  colorPrimaryDark: "#0F7F85",
  colorAccent: "#009688",
  error: "#dd0000",
  disabled: "#999999",
  white: "#FFFFFF",
  dimmedWhite: "#fcfcfc",
  grey: "#fafafa",
  primaryBackground: "#FFFFFF",
  transparent: "#FFFFFF",
  dark: "#000000",
  creamy: "#ffffcc",
  shadow: "#000000",
  calendar: "#f4f4f4",
  calendarSecondary: "#cdcccd",
  divider: "#efefef",
  paperPlaceHolder: "#efefef",
  paperText: "#404040",
  listItemPrimary: "#404040",
  listItemSecondary: "#A8A9AD",
  calendarCircleSelected: "#D9F1F4",
  calendarMonthTextColor: "#43BBC7",
  warning: "#FF1867",
};