import {
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_REQUEST,
  AUTH_CHECK_STORAGE,
  AUTH_ERROR_STORAGE,
  AUTH_SUCCESS_STORAGE,
  AUTH_CLEAN_DATA,
} from './AuthTypes'
import API from '../../utils/api'
import i18n from 'i18next'
import { userToken } from '../User/UserAction'
import { LocalStorageFunctions } from "../../services/LocalStorageService/types";
import { toast } from "react-toastify";

export const authSuccess = (token) => ({
  type: AUTH_SUCCESS,
  payload: token,
});

export const authRequest = () => ({
  type: AUTH_REQUEST,
});

export const authError = (error) => ({
  type: AUTH_ERROR,
  payload: error,
});

export const authStorageSuccess = (token) => ({
  type: AUTH_SUCCESS_STORAGE,
  payload: token,
});

export const authStorageCheck = (token?: string) => ({
  type: AUTH_CHECK_STORAGE,
  payload: token,
});

export const authStorageError = () => ({
  type: AUTH_ERROR_STORAGE,
});

export const authCleanData = (token?: string) => ({
  type: AUTH_CLEAN_DATA,
});

export const userDeviceToken = (token: string) => {
  return async () => {
    await API.Instance.post('/mobile_push_token', {token: token, type: 'push'})
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.error(e);
      });
  };
}

export const authAction = (login: string, password: string, localStorageFunctions: LocalStorageFunctions) => {
  return async (dispatch: any) => {
    dispatch(authRequest());
    await API.Instance.post('/login', {login, password})
      .then((response) => {
        const {data}: any = response;

        if (!API.isValidResponse(response)) {
          const errorMessage = data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

          dispatch(authError(errorMessage));
          throw errorMessage;
        }

        const token = data.data.token;

        localStorageFunctions.setItem("token", token);
        dispatch(authSuccess(token));
      })
      .catch((error) => {
        let errorText = error

        if (!error || error === '') {
          errorText = i18n.t('api:loginError')
        }

        toast.error(errorText);
      });
  };
};

export const authFacebookAction = (values: any, localStorageFunctions: LocalStorageFunctions) => {
  return async (dispatch: any) => {
    dispatch(authRequest());
    await API.Instance.post('/fb_login', values)
      .then((response) => {
        const {data}: any = response;

        if (!API.isValidResponse(response)) {
          const errorMessage = data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

          dispatch(authError(errorMessage));
          throw errorMessage;
        }

        const token = data.data.token;

        dispatch(authSuccess(token));
        localStorageFunctions.setItem("token", token);
      })
      .catch((error) => {
        let errorText = error;
        if (!error || error === '') {
          errorText = i18n.t('api:loginError');
        }

        toast.error(errorText);
      })
  }
}

export const authAppleAction = (values, localStorageFunctions: LocalStorageFunctions) => {
  return async (dispatch) => {
    dispatch(authRequest())
    await API.Instance.post('/apple_login', values)
      .then((response) => {
        const {data}: any = response;

        if (!API.isValidResponse(response)) {
          const errorMessage = data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

          dispatch(authError(errorMessage));
          throw errorMessage;
        }

        const token = data.data.token;

        dispatch(authSuccess(token));
        localStorageFunctions.setItem("token", token);
      })
      .catch((error) => {
        let errorText = error;
        if (!error || error === '') {
          errorText = i18n.t('api:loginError');
        }

        toast.error((errorText));
      })
  }
}

export const logoutAction = (localStorageFunctions: LocalStorageFunctions) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.user.token;
    console.log(token);
    await API.Instance.post('/clear_token', {token: token.token, type: token.os})
      .then((response: any) => {
        const {data} = response;

        if (!API.isValidResponse(response)) {
          dispatch(authError(data.message));
          throw data.message;
        }

        if (response.data.error) {
          dispatch(authError(response.data.error));
          throw data.message;
        }

        dispatch(userToken({token: '', os: ''}));
        dispatch(authCleanData(data.data.token));
        localStorageFunctions.removeItem("token");
      })
      .catch((e) => {
        console.error(e);
      })
  }
}

export const authStorage = (isAuthenticated: boolean, token: string) => {
  return async (dispatch: any) => {
    dispatch(authStorageCheck());

    if (isAuthenticated && token) {
      dispatch(authStorageSuccess(token));
      return;
    }

    dispatch(authStorageError());
  }
}
