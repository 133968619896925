import React, { useEffect, useRef } from 'react';
import moment from "moment";
import 'moment/min/locales';
import Login from "./containers/Login";
import { Colors } from "./consts/colors";
import { createTheme, THEME_ID, ThemeProvider } from "@mui/material";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Register from "./containers/Register";
import Dashboard from "./containers/Dashboard";
import Profile from "./containers/Profile";
import Settings from "./containers/Settings";
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from './modules/Auth/AuthAction';
import Api from "./utils/api";
import AboutApp from "./containers/AboutApp";
import Survey from "./containers/Survey";
import HistoryPoints from "./containers/HistoryPoints";
import Exchange from "./containers/Exchange";
import GroupFields from "./containers/GroupFields";
import SurveyView from "./containers/SurveyView";
import { Slide, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./components/ui/Loader";

moment.locale('pl');

const muiTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 45,
          paddingRight: 45,
        },
      }
    },
  },
  palette: {
    background: {
      default: Colors.primaryBackground
    },
    primary: {
      main: Colors.colorAccent,
    }
  },
  typography: {
    body2: {
      fontSize: '12px'
    },
  }
});

function App() {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      Api.checkIfAuthenticated().then((res) => {
        dispatch(AuthActions.authStorage(res.isAuthenticated, res.token?.replace(/^"|"$/g, '') || ""));
      });

      return;
    }

    // if (auth.authorized) {
      // Notification.requestPermission().then((permission) => {
      //   if (permission === 'granted') {
      //     navigator.serviceWorker.ready.then((sw) => {
      //       sw.pushManager.subscribe(({
      //         userVisibleOnly: true,
      //         applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
      //       })).then((subscription) => {
      //         dispatch(AuthActions.userDeviceToken(JSON.stringify(subscription)));
      //       });
      //     });
      //   }
      // });
    // }
  }, [auth, dispatch]);

  if (!auth.checkStorage) {
    return <Loader/>;
  }

  return (
    <ThemeProvider theme={{[THEME_ID]: muiTheme}}>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        toastStyle={{margin: 10}}
      />

      <Routes>
        <Route
          element={auth.authorized && auth.token !== '' ? <Outlet/> : <Navigate to="/" replace/>}
        >
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/survey" element={<Survey/>}/>
          <Route path="/exchange" element={<Exchange/>}/>
          <Route path="/groupFields" element={<GroupFields/>}/>
          <Route path="/historyPoints" element={<HistoryPoints/>}/>
          <Route path="/aboutApp" element={<AboutApp/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/surveyView" element={<SurveyView/>}/>
        </Route>

        <Route
          element={auth.authorized && auth.token !== '' ? <Navigate to="/dashboard" replace/> : <Outlet/>}
        >
          <Route path="/" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
