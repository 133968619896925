import { REGISTER_REQUEST, REGISTER_ERROR, REGISTER_SUCCESS, REGISTER_CLEAR } from './RegisterType'
import API from '../../utils/api'
import i18n from 'i18next'
import { toast } from "react-toastify";

export const registerSuccess = (email, link) => ({
  type: REGISTER_SUCCESS,
  payload: {
    email,
    link,
  },
});

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

export const registerError = (error) => ({
  type: REGISTER_ERROR,
  payload: error,
});

export const registerClear = () => ({
  type: REGISTER_CLEAR,
});

export const registerAction = (email: string, name: string, lastname: string) => {
  return async (dispatch: any) => {
    dispatch(registerRequest())
    await API.Instance.post('/register', {email, name, lastname})
      .then((response: any) => {
        if (!API.isValidResponse(response)) {
          const errorMessage = response.data?.message || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

          dispatch(registerError(errorMessage));
          throw errorMessage;
        }

        const {data} = response;

        dispatch(registerSuccess(data.data.email, data.data.link));
      })
      .catch((error) => {
        if (error) {
          toast.error(error);
        } else {
          toast.error(i18n.t('api:registerError'));
        }

        console.error(error);
      })
  }
};
