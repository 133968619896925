import { EXCHANGE_LIST_REQUEST, EXCHANGE_LIST_ERROR, EXCHANGE_LIST_SUCCESS } from './ExchangeListTypes'

const initialState = {
  exchangeList: [],
}

const exchangeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_LIST_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        fetchIsReady: false,
      }
    case EXCHANGE_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        fetchIsReady: false,
      }
    case EXCHANGE_LIST_SUCCESS:
      return {
        exchangeList: action.payload,
        error: '',
        isFetching: false,
        fetchIsReady: true,
      }
    default:
      return state
  }
};

export default exchangeListReducer;
