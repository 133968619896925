export const PROFILE_PROGRESS_SUCCESS = 'PROFILE_PROGRESS_SUCCESS';
export const PROFILE_PROGRESS_REQUEST = 'PROFILE_PROGRESS_FETCHING';
export const PROFILE_PROGRESS_ERROR = 'PROFILE_PROGRESS_ERROR';

export const PROFILE_GROUPS_SUCCESS = 'PROFILE_GROUPS_SUCCESS';
export const PROFILE_GROUPS_REQUEST = 'PROFILE_GROUPS_REQUEST';
export const PROFILE_GROUPS_ERROR = 'PROFILE_GROUPS_ERROR';

export const PROFILE_REMOVE_SUCCESS = 'PROFILE_REMOVE_SUCCESS';
export const PROFILE_REMOVE_REQUEST = 'PROFILE_REMOVE_REQUEST';
export const PROFILE_REMOVE_ERROR = 'PROFILE_REMOVE_ERROR';
export const PROFILE_REMOVE_CLEAR = 'PROFILE_REMOVE_CLEAR';

