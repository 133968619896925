import { useState } from 'react';
import { IUseInputFocusResult } from "./types";

const useInputFocus = (): IUseInputFocusResult => {
  const [inputActive, setInputActive] = useState<boolean>(false);

  const handleInputFocus = (): void => {
    setTimeout(() =>  setInputActive(true));
  }

  const handleInputBlur = (): void => {
    setTimeout(() =>  setInputActive(false));
  }

  return {inputActive, handleInputFocus, handleInputBlur};
}

export default useInputFocus;