import { SURVEY_REQUEST, SURVEY_ERROR, SURVEY_SUCCESS } from './SurveyTypes'

const initialState = {
  isFetching: false,
  active_surveys: [],
  archive_surveys: [],
  error: '',
}

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_REQUEST:
      return {
        ...state,
        error: '',
        isFetching: true,
      }
    case SURVEY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    case SURVEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        archive_surveys: action.payload
          .filter((item) => item.fas_status === '99' || item.fas_status === '98')
          .filter((item, index) => index < 30),
        active_surveys: action.payload.filter(
          (item) => (!item.fas_status || item.fas_status === '0') && item.status === '1'
        ),
      }
    default:
      return state
  }
};

export default surveyReducer;
