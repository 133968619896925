import { HISTORY_SUCCESS, HISTORY_ERROR, HISTORY_REQUEST } from './HistoryTypes';
import API from '../../utils/api'
import { toast } from "react-toastify";

export const historySuccess = (payload) => ({
  type: HISTORY_SUCCESS,
  payload
});

export const historyRequest = () => ({
  type: HISTORY_REQUEST,
});

export const historyError = (error) => ({
  type: HISTORY_ERROR,
  payload: error,
});

export const historyAction = () => {
  return async (dispatch: any) => {
    dispatch(historyRequest());
    await API.Instance.get('/points_history').then((response: any) => {
      if (!API.isValidResponse(response)) {
        const errorMessage = response.data?.error || "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.";

        dispatch(historyError(errorMessage));
        toast.error(errorMessage, {position: "bottom-center"});
        return;
      }

      const {data} = response.data;

      dispatch(historySuccess(data.items));
    })
  }
}
