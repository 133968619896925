import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const ProfileFieldSelect = ({value, field, onChange}) => {

  return (
    <FormControl variant="standard">
      <InputLabel id={field.name}>{field.label}</InputLabel>
      <Select labelId={field.name} label={field.label} fullWidth variant={"standard"} value={value}
              onChange={(event: SelectChangeEvent<any>) => onChange(field.name, event.target.value)}>
        {field.options.map((option: any) => (
          <MenuItem key={option.id} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProfileFieldSelect;