import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton, ListItemText,
  Typography
} from '@mui/material';
import ToolbarWrapper from "../../components/ui/ToolbarWrapper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as HistoryActions from "../../modules/History/HistoryAction";
import { useSwipeable } from "react-swipeable";
import moment from "moment";

const HistoryPoints = () => {
  const {t} = useTranslation('login');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useSelector((state: any) => state.history);
  const isFetching = useSelector((state: any) => state.history.isFetching);
  const user = useSelector((state: any) => state.user);
  const firstUpdate = useRef(true);
  const listRef = useRef<any>(null);
  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      const {absY} = eventData;
      const threshold = 50;
      const isOutsideList = absY < -threshold || absY > threshold;
      const isScrolledToTop = listRef.current?.scrollTop === 0;

      if (isOutsideList && isScrolledToTop) {
        fetchOptions();
      }
    }
  });
  const fetchOptions = useCallback(() => {
    dispatch(HistoryActions.historyAction());
  }, [dispatch]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      fetchOptions();

      return;
    }
  }, [fetchOptions]);

  const refPassthrough = (el): void => {
    handlers.ref(el);

    listRef.current = el;
  }

  return (
    <>
      <ToolbarWrapper hasToolbar={true} toolbarConfig={{
        leftItems: <IconButton sx={{color: "#009688"}} onClick={() => {navigate("/dashboard")}}><ArrowBackIosNewIcon/></IconButton>,
        text: "Historia punktów"
      }}>
        <Box sx={{width: "100%"}} display={"flex"} justifyContent={"center"} marginY={2}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography lineHeight={1} color={"#009688"} variant={"h6"}>{user.points}</Typography>
            <Typography color={"#666666"} variant={"body2"}>Aktualny stan</Typography>
          </Box>
          <Divider sx={{marginX: 3}} orientation="vertical" variant="middle" flexItem/>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography lineHeight={1} color={"#009688"} variant={"h6"}>{user.points_outgoing}</Typography>
            <Typography color={"#666666"} variant={"body2"}>Wydane</Typography>
          </Box>
          <Divider sx={{marginX: 3}} orientation="vertical" variant="middle" flexItem />
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography lineHeight={1} color={"#009688"} variant={"h6"}>{user.points_incoming}</Typography>
            <Typography color={"#666666"} variant={"body2"}>Zebrane</Typography>
          </Box>
        </Box>

        {isFetching && (
          <Box paddingY={2} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress/>
          </Box>
        )}

        {!history.surveys ? (
          <>
            <Typography>{t("exchange:empty")}</Typography>
          </>
        ) : (
          <>
            <Box overflow={"auto"} {...handlers} ref={refPassthrough} height={"100%"}>
              <List>
                {history.surveys.map((res) => (
                  <ListItem key={res.id} disablePadding>
                    <ListItemButton divider>
                      <ListItemText
                        primary={res.description}
                        primaryTypographyProps={{ color: '#009688' }}
                        secondary={
                          <>
                            {`${res.value} ${t('history:points')}`}
                            <br />
                            {moment(res.adddat).format("YYYY-MM-DD")}
                          </>
                        }
                      />
                      <Typography color={(res.type === 'incoming') ? '#009688' : '#FF0000'}>
                        {res.type === 'incoming' ? '+' : '-'}
                        {res.value}
                        pkt
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}
      </ToolbarWrapper>
    </>
  );
};

export default HistoryPoints;