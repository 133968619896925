import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { CircularProgressWithLabelProps } from "./types";

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  props,
  fontSize = 12
}) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top="0"
        left="0"
        bottom="0"
        right="0"
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          fontSize={fontSize}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
